import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Today's AMA recording`}</h1>
    <p>{`yo!`}</p>
    <p>{`It looks like y'all liked the calculators and tools, so i'll try to do more of
those in the coming weeks`}</p>
    <p>{`If you missed it, i hosted an AMA earlier and thought some really good questions
were asked - it's about 75 mins long and you can watch it by clicking the image
below`}</p>
    <p><a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/mvudkm494nf5hvn5ezbm/x0hph6hn8exko4b5/aHR0cHM6Ly92aW1lby5jb20vNDc2NDU0Njk5LzFkODdmYjEwOGI="
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1528px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEBQb/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAWWWI3pDIDr/AP/EABoQAAMAAwEAAAAAAAAAAAAAAAECAwAEETL/2gAIAQEAAQUCBE117hbvQBn8qO4xPf/EABURAQEAAAAAAAAAAAAAAAAAAAAS/9oACAEDAQE/AUv/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAHBAAAQMFAAAAAAAAAAAAAAAAAAECEhARMUFx/9oACAEBAAY/ApLotJYuMidHU//EABkQAQEBAQEBAAAAAAAAAAAAAAERACFBsf/aAAgBAQABPyF+lPrImnAvS6ESO7wKj4UzrXu//9oADAMBAAIAAwAAABA8z//EABYRAQEBAAAAAAAAAAAAAAAAAAARQf/aAAgBAwEBPxC4t//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QjCn/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMWGxUXH/2gAIAQEAAT8Q3xfY20k335gF5ikL014Hr7j485F4zUPCVO9vuFhrYsjiNKgVfzP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Today's AMA",
            "title": "Today's AMA",
            "src": "/static/8f08bf1819d1354d4a16f413565746e2/fe45d/ama-recording-screenshot.jpg",
            "srcSet": ["/static/8f08bf1819d1354d4a16f413565746e2/a6407/ama-recording-screenshot.jpg 400w", "/static/8f08bf1819d1354d4a16f413565746e2/a24e6/ama-recording-screenshot.jpg 800w", "/static/8f08bf1819d1354d4a16f413565746e2/fe45d/ama-recording-screenshot.jpg 1528w"],
            "sizes": "(max-width: 1528px) 100vw, 1528px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p>{`until sunday,`}<br parentName="p"></br>{`
`}{`shaan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      